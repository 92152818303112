<template>
<div>
    <div class="columns">
        <div class="column is-one-third has-text-left">
            <h2><i class="mdi mdi-human mr-2" style="color:#fc9900"></i>¿Buscas un técnico? </h2>
            <h3 class="mt-2 mb-1">Así funciona:</h3>
            <ul>
                <li>Selecciona la especialidad que necesitas.</li>
                <li>Elije los técnicos y chatea con ellos.</li>
                <li>Si elegiste pagar a travez de tecniko.com con "servicio pago" obtienes una garantia del servicio.</li>
                <li>El técnico realiza el servicio.</li>
                <li>Califica el servicio y el técnico.</li>
                <li>Si pagaste con "servicio pago", el pago se libera al técnico 48 horas después que indiques que el servicio fué satisfactorio.</li>
            </ul>
        </div>
        <div class="column">
            <img src="/img/como-funciona-tecniko.webp" alt="">
        </div>
    </div>
        
    <div class="columns">
        <div class="column has-text-left">
            <h2>
                <i class="mdi mdi-wrench" style="color:#adadad"></i>
                ¿Eres Técnico?
            </h2>
       
            <p class="m-2">Si eres electricista, plomero, mecánico, técnico en refrigeración, linea blanca, pc, celular ó fumigación y prestas un servicio de calidad te invitamos a registrarte.</p>
            <div v-if="$session.exists() && $session.get('user').id_role == 2 || !$session.exists()" position="is-centered"  class="roboto">
              <router-link :to="'/tech-sign-up'" style="margin:10px;font-size:19px;">
                <u>Postulate como técnico para ofrecer tus servicios</u>
              </router-link>
          </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'ComoFunciona',
    // Add your component's logic here
}
</script>

<style scoped>
h2{
    font-size: 26px;
    font-weight: bold;
}
h3{
    font-size: 22px;
}
ul{
    list-style:circle;
}
li{
    margin-left: 20px;
    margin-bottom: 10px;
}
</style>