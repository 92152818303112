<template>
    <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="container">

            <div class="navbar-brand is-justify-content-space-between" >
                <a class="navbar-item" href="/">
                    <img class="logo" src="/img/logo_black.png" alt="Tecniko" >
                </a>
                <div class="is-flex" @click="toggleMenu">
                    <span v-if="user" class="navbar-item hi-user is-hidden-desktop">
                        Hola, {{ user.first_name }}
                    </span>
                    <a class="navbar-burger burger" :class="{'is-active':isMenuOpen}" role="button" aria-label="menu" aria-expanded="false" 
                        style="">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>
            </div>

            <div class="navbar-menu" :class="{ 'is-active': isMenuOpen }">
                
                <div class="navbar-start">
                    <div class="navbar-item has-dropdown is-hoverable">
                        <div class="navbar-link is-hidden-touch">
                            Inicio
                        </div>
                        <div class="navbar-dropdown">

                            <router-link to="/" class="navbar-item" v-if="user && user.id_role==2">
                                <img src="/img/head/inbox.png" >
                                Mis Solicitudes
                            </router-link>

                            <router-link to="/my-searches" class="navbar-item" v-if="user && user.id_role!=2 && user.is_ghost==0 ">
                                <img src="/img/head/outbox.png" >
                                Mis Búsquedas
                            </router-link>
                            
                            <router-link to="/" class="navbar-item" v-if="!user || user.id_role!=2">
                                <img src="/img/head/search_techs.png" >
                                Buscar Técnicos
                            </router-link>
                            
                            <router-link to="/tech-sign-up" class="navbar-item" v-if="!user" >
                                <img src="/img/head/register_techs_icon.png" >
                                Registro para Técnicos
                            </router-link>
                            
                            <router-link to="/faq" class="navbar-item">
                                <img src="/img/head/faq.png" >
                                Preguntas Frecuentes
                            </router-link>

                        </div>
                    </div>
                </div>

                <div class="navbar-end">
                    
                    <router-link v-if="!user" to="/login" class="navbar-item button is-warning mt-1 mx-2" id="login-btn" >
                        Iniciar Sesión
                    </router-link>
                    
                    <div v-else class="logged-user-block navbar-item has-dropdown is-hoverable">
                        <Wallet :user="user" v-show="user.id_role==2" class="ml-5"/>
                        <a class="navbar-link hi-user is-hidden-touch">
                            Hola, {{ user.first_name }}
                        </a>
                        <div class="navbar-dropdown">
                            <router-link :to="myProfilePath" class="navbar-item">
                                Mi Cuenta
                            </router-link>
                            <a class="navbar-item" @click="logout">
                                Cerrar Sesión
                            </a>
                        </div>
                    </div>

                    <b-tooltip v-if="!user" label="Servicio para Venezuela"
                        position="is-bottom"
                        type="is-black"
                        class="pt-4 is-hidden-touch">
                            <img class="flag" src="/img/head/flag.png" alt="Venezuela">
                    </b-tooltip>
                    
                </div>

            </div>
        </div>
    </nav>
</template>

<script>
import LoginService from '@/services/auth/LoginService';
import Wallet from '@/components/wallet/balance-icon-link';

export default {
    
    components: {
      Wallet
    },

    data() {
        return {
            isLoading: false,
            user: null,
            isMenuOpen: false
        };
    },

    mounted() {
      this.user = this.$session.get('user');
    },

    computed: {
        myProfilePath() {
            if(this.user.id_role == 2) {
                return '/my-tech-profile';
            } else {
                return '/my-profile';
            } 
        }
    },

    watch: {
        $route(to, from) {
            this.isMenuOpen = false;
        }
    },

    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        logout() {
            this.isLoading = true;
            const loginService = new LoginService();
            loginService.logout(process.env.VUE_APP_APIURL).then(response => {
                this.$session.destroy();
                if(this.$router.name != '/'){
                    this.$router.push('/');
                }
                this.user = this.$session.get('user');
                this.isLoading = false;
            });
        },
    },

};
</script>

<style scoped>
.navbar-link::after {
  border-color: #ed9719 !important;
}
.navbar-start a img {
    margin-right: 12px;
    max-width: 26px;
}
#login-btn::after {
  font: normal 12px;
  content: '\279C';
  margin-left: 6px;
}
.hi-user {
    border: 1px solid #ddd;
    border-radius: 24px;
    margin: 8px;
    font-size: 13px;
}
.logged-user-block .hi-user {
    padding: 5px 26px 5px 10px;
}
</style>