// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);"]);
// Module
exports.push([module.id, "\np[data-v-527189a9] {\n  font-family:'Roboto';\n}\n.field[data-v-527189a9] {\n  font-family:'Roboto';\n}\n.roboto[data-v-527189a9] {\n  font-family:'Roboto' !important;\n}\n.fade-enter-active[data-v-527189a9],\n.fade-leave-active[data-v-527189a9] {\n  transition-duration: 0.3s;\n  transition-property: height, opacity;\n  transition-timing-function: ease;\n  overflow: hidden;\n}\n.fade-enter[data-v-527189a9],\n.fade-leave-active[data-v-527189a9] {\n  opacity: 0\n}\n", ""]);
// Exports
module.exports = exports;
