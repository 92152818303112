<template>
  <section class="main pt-6">
    <div class="has-text-centered container">

      <h1>
        Te ayudamos a buscar el Técnico <br/> en
        <transition
          enter-active-class="animate__animated animate__flipInX"
          leave-active-class="animate__animated animate__flipOutX">
          <span v-if="showRotateSpecials" class="focused-word" >{{ specialities[0] }}</span>
        </transition>
        que necesitas
      </h1>

      <!--span v-if="showRotateSpecials" :id="'rotating-scpec-'+rotatingSpecialityIndex" class="focused-word" >
        {{ specialities[rotatingSpecialityIndex] }}
      </span-->
      <!-- <span v-for="(spec,i) in specialities" :key="i" >
        <span v-show="rotatingSpecialityIndex===i" class="focused-word">{{ spec }}</span>
      </span> -->

      <div id="form-container">

        <div class="step-1">
          <p>Selecciona la especialidad:</p>
          <nav>
              <div id="homeCategoryIcons">
                <div
                  v-for="(element, index) in categories"
                  :key="index"
                  :class="{'is-focused' : (index==currentIndex) , 'categoryItem': true }"
                  @click="setCategory(index)"
                  >
                  <figure>
                    <img :src="'img/icons-categories/'+element.slug+'.svg'" alt="">
                  </figure>
                  
                  <h3>{{ element.name }}</h3>
            
                </div>
              </div>
          </nav>
        </div>

        <!-- Modal Step 2: Estado, ciudad, modalidad-->
        <div class="modal" :class="{'is-active':formStep===2}">
          <div class="modal-background"></div>
          <div class="modal-content">
            <div class="step-2-modal-card">
            <i class="mdi mdi-close" style="cursor: pointer; float: right;"
              @click="formStep=1"></i>

              <b-icon pack="fas" icon="xmark" size="is-small"></b-icon>
              <button class="modal-close is-large" aria-label="close"></button>

              <h1>Contactar Técnicos en <strong>{{ selectedCategory }}</strong></h1>

              <p>Tu ubicación:</p>

              <div id="homeLocationSelectors">          
                <b-select id="state-selector"
                          class="location-select"
                          v-model="selectedState"
                          style="min-width:400px"
                          @input="getCities(selectedState)"
                          icon="city">
                    <option value="" disabled hidden>Selecciona tu estado</option>
                    <option v-for="state in states"
                            :value="state.id"
                            :key="state.id">
                            {{ state.name }}
                    </option>
                </b-select>
                <b-select id="city-selector" 
                          class="location-select"
                          v-model="selectedCity"
                          placeholder="Selecciona tu municipio" 
                          @input="setCity(selectedCity)"
                          style="min-width:400px"
                          icon="home-modern">
                  <option value="" disabled hidden>Selecciona tu municipio</option>
                  <option v-for="city in cities"
                          :value="city.id"
                          :key="city.id">
                          {{ city.name }}
                  </option>
                </b-select>
              </div>

              <div class="is-flex is-justify-content-center pt-2">  
                <b-radio v-model="modeSlug"
                    size="is-medium"
                    type="is-danger"
                    native-value="urgencia">
                    Urgencia
                </b-radio>
                <b-radio v-model="modeSlug"
                    size="is-medium"
                    type="is-warning"
                    native-value="presupuesto">
                    Presupuesto
                </b-radio>
              </div>

              <b-button type="is-warning"
                size="is-medium"
                id="search-button"
                icon-left="account-search"
                @click="next()"
                style="margin-top:30px;min-width:250px">
                Buscar
              </b-button>

            </div>
          </div>
        </div>
        <!-- / End .modal Step 2-->
        
      </div>
      
      <hr>
      
      <Testimonials />
      <hr>

      <ComoFunciona />
      <hr>

      <web-app-footer/>
      
    </div>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false">
    </b-loading>
  </section>
</template>

<style scoped>
  section.main {
    background-color: #f9f9f9;
  }

  /* Step 2: Modal: State, City, Mode */
  .step-2-modal-card {
    background-color:#fff;
    border-radius: 12px;
    padding:12px ;
    max-width: 600px;
    margin:0 auto;
  }
  #homeLocationSelectors{
    max-width:420px;
    margin: 0 auto 20px auto;
  }
  #homeLocationSelectors .control{
    /*padding: 4px;*/
    font-size: 17px;
    margin-bottom: 14px;
  }
  /* End Step2 */


  h1 {
    font-size: 26px;
    margin-bottom: 68px;
    font-weight: bold;
    margin-bottom:20px
  }

  strong {
    border-bottom: 2px solid #fc9900;
  }
  .focused-word {
    color:#fc9900;
    display:inline-block;
    min-width: 150px
  }

  #form-container {
    background-color: rgb(0 0 0 / 2%);
    border-radius: 12px;
    padding: 15px;
    max-width: 660px;
    margin: 0 auto;
  }
  
  p {
    font-weight: 400;
    font-size: 18px;
    margin:4px auto 10px;
  }

  #search-button {
    width:100%;
  }

  @media (max-width: 700px) {
    h1 {
      font-size: 24px;
    }
    .location-select{
      min-width:100% !important;
    }
  }

  @media (max-width: 420px) {
    h1 {
      font-size: 22px;
    }
    #form-container {
      padding: 0 0 25px !important;
    }
  }

  @media (max-width: 350px) {
    h1 {
      font-size: 23px;
    }
  }
</style>

<script>
  import ComoFunciona from '@/components/home/como-funciona.vue';
  import Testimonials from '@/components/home/testimonials.vue';
  import footer from '@/components/layout/Footer.vue';
  
  export default {
    components: {
      ComoFunciona,
      Testimonials,
      'web-app-footer': footer
    },

    data() {
       return {
          formStep: 1,
          specialities: ['Electricidad','','Refrigeración','', 'Computación','', 'Plomería','', 'Mecánica', '','Linea Blanca', '', 'Fumigación', ''],
          showRotateSpecials: true,
          //specialities: ['Electricidad','Refrigeración', 'Computación', 'Plomería', 'Mecánica', 'Linea Blanca', 'Fumigación'],  
          //rotatingSpecialityIndex: 0,
          isActivePoll: true,
          categories: [],
          selectedCategory: '',
          selectedCategoryId: '',
          selectedCategorySlug: '',
          states: [],
          selectedState: '',
          selectedStateLabel: '',
          cities: [],
          selectedCity: '',
          selectedCityLabel: '',
          selectedCitySlug: '',
          currentIndex:-1,
          isLoading: true,
          modeSlug:'presupuesto',
        }
    },

    created(){
      // Mount Animation lib CSS
      const animateScript = document.createElement('link');
      animateScript.setAttribute('href','https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css');
      animateScript.setAttribute('rel','stylesheet'); 
      document.head.appendChild(animateScript);
    },

    mounted(){
      this.loadStates();
      this.loadCategories();
      // Animación texto encabezado:
      window.setInterval(this.shiftSpeciality, 700);

      /*console.log('Home mounted');
      let reslt = gtag('event', 'conversion', {'send_to': 'AW-10897707205/-NmcCM-P48QDEMWht8wo'});
      console.log(reslt);*/
      /*if(this.$session.exists() && this.$session.get('user').is_temporary_password === 1){
        EventBus.$emit('closePoll2', 1);
        this.openChangePassword = true;
      }*/
    },

    methods: {
      shiftSpeciality(){
        const first = this.specialities.shift();
        this.specialities = this.specialities.concat(first);
        this.showRotateSpecials = !this.showRotateSpecials;
      },
      /*shiftSpecialityV2(){
        if(this.rotatingSpecialityIndex < this.specialities.length - 1){
          this.rotatingSpecialityIndex++;
        } else {
          this.rotatingSpecialityIndex = 0;
        }
        //this.showRotateSpecials = !this.showRotateSpecials;
      },*/
      loadStates() {
        /* //API Call
        axios.get(process.env.VUE_APP_APIURL + 'get-active-states').then(response => {
          this.states = response.data.states;
        }).catch(e => {
          console.log('Critical ERROR: Cant get active states.');
        })
        */
        // Hardcoded
        this.isLoading = false;
        this.states = [
          {"id": 7, "name": "Carabobo"},
          {"id": 26, "name": "Gran Caracas"}
        ];
      },
      loadCategories() {
        /* //API Call
        axios.get(process.env.VUE_APP_APIURL + 'get-active-categories').then(response => {
          this.categories = response.data.categories;
          this.isLoading = false;
        }).catch(e => {
          console.log('Critical ERROR: Cant get active states.');
        })*/
        // Hardcoded
        this.isLoading = false;
        this.categories = [
          {
              "id": 2,
              "name": "Mec\u00e1nica",
              "slug": "mecanico"
          },
          {
              "id": 3,
              "name": "Plomer\u00eda",
              "slug": "plomero"
          },
          {
              "id": 4,
              "name": "Refrigeraci\u00f3n",
              "slug": "nevera-aire-acondicionado"
          },
          {
              "id": 6,
              "name": "PC",
              "slug": "pc"
          },
          {
              "id": 8,
              "name": "Electricista",
              "slug": "electricista"
          },
          {
              "id": 9,
              "name": "Celular",
              "slug": "celular"
          },
          {
              "id": 10,
              "name": "L\u00ednea Blanca",
              "slug": "linea-blanca"
          },
          {
              "id": 12,
              "name": "Fumigaci\u00f3n",
              "slug": "fumigacion"
          }
        ];
      },
      getCities(stateId) {
        this.isLoading = true;

        this.selectedStateLabel = this.states.filter(function(elem){
          if(elem.id == stateId) return elem.name;
        });

        axios.get(process.env.VUE_APP_APIURL + 'get-cities-by-state/' + stateId).then(response => {
          this.cities = response.data.cities;
          this.isLoading = false;
        }).catch(e => {
          console.log('Critical ERROR: Cant get active states.');
          this.isLoading = false;
        })
      },
      setCategory(index){
        this.selectedCategory = this.categories[index].name;
        this.selectedCategoryId = this.categories[index].id;
        this.selectedCategorySlug = this.categories[index].slug;
        this.currentIndex = index;
        this.formStep = 2;
      },
      setCity(id){
        this.selectedCityLabel = this.cities.filter(function(elem){
          if(elem.id == id) return elem.name;
        });
        this.selectedCitySlug = this.cities.filter(function(elem){
          if(elem.id == id) return elem.slug;
        });
        
      },
      next(){
        this.isLoading = true;
        // Previous validations
        if(this.selectedCategory == '' || this.selectedState == '' || this.selectedCity == ''){
          this.$buefy.notification.open({
              duration: 5000,
              message: `Los campos <b> estado y municipio</b> son obligatorios para realizar una búsqueda.`,
              position: 'is-bottom',
              type: 'is-danger',
              hasIcon: true
          })
          this.isLoading = false;
          return;
        }
        
        const path = `${this.selectedCategorySlug}/${this.selectedCitySlug[0].slug}/${this.modeSlug}`;
        this.$router
          .push({path})
          .catch((e)=>{
            console.log(e);
          });
      }
    },

  }
  
</script>