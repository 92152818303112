export const refreshPageMixin = {
    data() {
      return {
        currentHash: '{{POST_BUILD_ENTERS_HASH_HERE}}',
        token: localStorage.getItem('user-token'),
        hashChanged: false,
        newHash: ''
      }
    },
    methods: {
    initVersionCheck(url, frequency = 1000 * 60 * 2) {
        setInterval(() => {
          this.checkVersion(url);
        }, frequency);
      },
      async checkVersion(url) {
        try {
          const fileResponse = await axios.get(`${url}?t=${new Date().getTime()}`);
          this.newHash = fileResponse.data.hash;
          this.hashChanged = this.hasHashChanged(this.currentHash, this.newHash);
          
          if(this.hashChanged){
              this.reloadApp();
          }
          
        } catch (error) {
          this.loading = false;
          if (!error.response) {
            this.errorStatus = 'Error: Network Error'
          } else {
            this.errorStatus = error.response.data.message;
          }
        }
      },
      hasHashChanged(currentHash, newHash) {
        if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
          return true;
        }
  
        return currentHash !== newHash;
      },
      reloadApp() {
        this.currentHash = this.newHash;
        window.location.reload();
      }
    }
  };