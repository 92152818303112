export default class LoginService {
  /**
   * Login clients & techs
   */
  login(contextPath, email, password) {
    let formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("remember_me", 1);

    return axios.post(
      contextPath + 'login',
      formData, 
      {
        headers: { 
          'Content-Type' : 'multipart/form-data',
        }
      }).then(response => {
        localStorage.setItem('access_token', response.data.access_token);
        localStorage.setItem('token_type', response.data.token_type);
        return response;
    }).catch(e => {
      return e.message;
    })
  }

  /**
   * Logout clients & techs 
   * @param {*} contextPath 
   * @returns 
   */
  logout(contextPath) {
    const AuthStr = localStorage.token_type.concat(' ').concat(localStorage.access_token);
    return axios.get(contextPath + 'logout', { headers: { Authorization: AuthStr } }).then(response => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('token_type');
      return response.data;
    }).catch((error) => {
      return error;
    });
  }

  /**
   * Get current logged in user
   * @param {*} contextPath 
   * @returns 
   */
  getUser(contextPath) {
    return axios.get(
      contextPath + 'get-my-user',
      {
        headers: { 
          'Content-Type' : 'multipart/form-data',
          'Access-Control-Allow-Origin': true,
          'Authorization' : localStorage.token_type + ' ' + localStorage.access_token,
        }
      }).then(response => {
        return response;
      }).catch(e => {
        console.log(e);
        return e.message;
      })
  }
}
