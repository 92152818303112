<template>
  <div>
    <site-header v-show="$route.meta.header === 1"/>
    <mini-header v-show="$route.meta.header === 2"/>

    <main>
      <transition name="fade" mode="out-in" 
        @enter="enter" 
        @afterEnter="afterEnter"
        @beforeLeave="beforeLeave">
          <router-view/>
      </transition>
    </main>
    
  </div>
</template>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

p {
  font-family:'Roboto';
}

.field {
  font-family:'Roboto';
}

.roboto {
  font-family:'Roboto' !important;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>

<script>
  import axios from 'axios';
  import siteHeader from '@/components/layout/SiteHeader.vue'
  import headerMini from '@/components/layout/HeaderMini.vue'
  import { refreshPageMixin } from '@/config/refresh-page.mixin';

  export default {
    name: 'App',
    
    components: {
      'mini-header':headerMini,
      'site-header': siteHeader
    },
    
    mixins: [refreshPageMixin],
    
    created() {
      window.axios = axios;
      //Vue.prototype.$axios = axios; // Mas limpio asi como plugin vue, pero hay que editar todas las referencias
    },
    
    mounted(){
      this.initVersionCheck('/version.json');// console.log('App mounted')
    },

    methods : {
      enter(element) {
        const { height } = getComputedStyle(element);

        element.style.height = this.prevHeight;

        setTimeout(() => {
          element.style.height = height;
        });
      },
      afterEnter(element) {
        element.style.height = 'auto';
      },
      beforeLeave(element) {
        this.prevHeight = getComputedStyle(element).height;
      },
    }
  }
</script>