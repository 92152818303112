<template>
<div class="columns is-multiline mt-4">

    <div class="column is-12">
      <h2 class="is-size-6 has-text-weight-semibold has-text-grey">
        Otros usuarios opinaron:
      </h2>
    </div>

    <div class="column is-two-thirds is-offset-2">
      <div class="testimonial-slider is-flex">

        <div @click="prevTestimonial" class="is-flex is-flex-direction-column is-align-self-center arrow-area">
          <i class="mdi mdi-arrow-left" style="color:#fc9900"></i>
          <small>Anterior</small>
        </div>

        <div v-for="(testimonial, index) in testimonials" 
            :key="index" 
            v-show="currentTestimonial === index"
            style="flex-grow: 2;"
            class=""> 
              
            <div class="px-1 py-2 chat-bubble">  
              <p class="comment">"{{ testimonial.comment }}"</p>
              <div class="star-rating">
                <span v-for="star in 5" :key="star" class="star" :class="{ 'active': star <= testimonial.rating }">★</span>
              </div>
            </div>

            <div class="columns" id="people">
              <div class="column">
                  Cliente: <br>
                  <img src="/img/myface_none.png" alt="cliente"><br>  
                  {{ testimonial.clientName }}, 
                  <em>{{ testimonial.location }}</em>
              </div>
              <div class="column tech">
                  Técnico: <br>
                  <img :src="testimonial.technicianImg" :alt="'técnico '+testimonial.category" > <br>
                  {{ testimonial.technicianName }}, {{ testimonial.category }}
                  
              </div>
            </div>
        </div>

        <div @click="nextTestimonial" class="is-flex is-flex-direction-column is-align-self-center arrow-area" >
          <i class="mdi mdi-arrow-right" style="color:#fc9900"></i>
          <small>Siguiente</small>
        </div>

      </div>
    </div>
    
</div>
</template>

<script>
export default {
  data() {
    return {
      currentTestimonial: 0,
      testimonials: [{
        rating: 5,
        clientName: 'Jorge P',
        location: 'Valencia, Carabobo',
        technicianName: 'Jorge Camero',
        experience: '16 años',
        category: 'Refrigeración',
        technicianImg: 'img/home/jorge-camero.jpeg',
        comment: 'Excelente trato y conocimiento del área'
      },
      {
        rating: 4,
        clientName: 'María R',
        technicianName: 'Evelio Gomez',
        location: 'Valencia, Carabobo',
        experience: '21 años',
        category: 'Plomería',
        technicianImg: 'img/home/evelio-gomez.jpeg',
        comment: 'Excelente Profesional. El trabajo fue el esperado. Gracias por la pronta respuesta y paciencia.'
      }]
    }
  },

  mounted() {
    setInterval(() => {
      this.nextTestimonial();
    }, 3000);
  },

  methods: {
    nextTestimonial() {
      if (this.currentTestimonial < this.testimonials.length - 1) {
        this.currentTestimonial++;
      } else {
        this.currentTestimonial = 0;
      }
    },
    prevTestimonial() {
      if (this.currentTestimonial > 0) {
        this.currentTestimonial--;
      } else {
        this.currentTestimonial = this.testimonials.length - 1;
      }
    }
  }
}
</script>

<style scoped>


small{
  font-size: 10px;
  color: #545454;
}

.chat-bubble {
  background-color: #BBC89C; border-radius: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 768px) {
  .chat-bubble {
    min-height: 150px;
  }
}

.chat-bubble:after, .chat-bubble:before {
	top: 100%;
	left: 60px;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
}
.chat-bubble:after {
	border-top-color: #BBC89C;
	border-width: 10px;
	margin-left: -10px;
}
.star-rating{
  font-size: 20px;
}
.star-rating .star {
  color: gray;
}
.star-rating .star.active {
  color: gold;
}
.arrow-area{
  cursor: pointer;
}
#people{
  font-size: 12px;
  color: #545454;
}
#people img{
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
</style>