import { required, length, email, min, max, ext, confirmed } from "vee-validate/dist/rules";
import { extend, localize  } from "vee-validate";
import lang from 'vee-validate/dist/locale/es.json';
import moment from 'moment';

extend("required", {
  ...required,
  message: "Ingrese el campo {_field_}."
});
extend("required_spec", {
  ...required,
  message: "Ingrese al menos una especialidad."
});
extend("email", {
  ...email,
  message: "El email no es válido."
});
extend("required-files", {
  ...required,
  message: "Debe adjuntar 2 archivos para continuar."
});
extend("ext-files", {
  ...ext,
  message: "El formato de ambos archivos debe ser jpg o png."
});
extend("files-length", {
  ...length,
  message: "Debe adjuntar 2 archivos para continuar"
});
extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Las password no coinciden.'
});
extend('verify_password', {
  message: 'Las password debe contener al menos: 1 mayúscula, 1 número y un caracter especial.',
  validate(value){
    var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    return strongRegex.test(value);
  },
});
extend('min-age', {
  message: 'La edad mínima es de 18 años.',
  validate(value){
    var eightYearsAgo = moment().subtract("years", 18);
    var birthday = moment(value);

    if(!birthday.isValid()){
      return false;
    }else if (eightYearsAgo.isAfter(birthday)){
      return true;
    }else{
      return false;
    }
  },
});
extend("min", min);
extend("max", max);
extend("length", length);
extend("ext", ext);
extend("confirmed", confirmed);

localize('es');
localize('es', lang);