import Vue from 'vue'
import VueSession from 'vue-session'
import VueCurrencyInput from 'vue-currency-input'
import infiniteScroll from 'vue-infinite-scroll'// ver nota en router sobre Mis Busquedas
//import VueGtag from "vue-gtag";
import App from './App.vue'
import router from './config/router'
import './config/buefy'
import './config/filters'
import './config/vee-validate'
import '../static/css/global.css'

export const EventBus = new Vue();

Vue.use(VueSession);
Vue.use(VueCurrencyInput);
Vue.use(infiniteScroll);
// Google Analytics
/*Vue.use(VueGtag, {
  config: {id: process.env.VUE_APP_GOOGLE_ANALYTICS}
});*/

Vue.config.productionTip = false;
Vue.config.ignoredElements = ['b-field', 'b-autocomplete', 'b-input'];//Eliminar o no improtar estos componentes

if ('serviceWorker' in navigator) {
  /*navigator.serviceWorker
    .register('/service-worker-cache.js')
    .then(() => {
      console.log('Service Worker Registered')
    })*/
}

new Vue({
  el: '#app',
  router,
  components: {App},
  template: '<App/>'
});