import Vue from 'vue'
import 'buefy/dist/buefy.min.css'

// Import all components if needed:
//import Buefy from 'buefy'
//Vue.use(Buefy);

// Or just import what you need:
import { 
    Field, 
    Input,
    Select, 
    Checkbox,
    Radio, 
    Button,
    Switch, 
    Taginput, 
    Datepicker,
    Modal, 
    Loading, 
    Skeleton,
    Icon, 
    Image,
    Tag,
    Tooltip,
    Rate,
    Message, 
    Notification, // Used programatically as  this.$buefy.notification
    Dialog, // Used programatically as  this.$buefy.dialog
    Toast, // Used programatically as  this.$buefy.toast
    Tabs, 
    Steps 
} from 'buefy'

Vue.use(Dialog)
Vue.use(Toast)
Vue.use(Button)
Vue.use(Input)
Vue.use(Field)
Vue.use(Modal)
Vue.use(Tag)
Vue.use(Switch)
Vue.use(Loading)
Vue.use(Select)
Vue.use(Checkbox)
Vue.use(Tooltip)
Vue.use(Skeleton)
Vue.use(Message)
Vue.use(Notification)
Vue.use(Rate)
Vue.use(Radio)
Vue.use(Image)
Vue.use(Tabs) // +  Tabitem
Vue.use(Icon)
Vue.use(Steps)// +  Stepitem
Vue.use(Taginput)
Vue.use(Datepicker)
