<template>
  <div class="logged-user-block roboto payment-buttons-effect" @click="$router.push('/my-wallet')" v-if="this.user && this.$session.get('user').id_role == 2 && this.$session.get('user').is_ghost == 0" style="border:none">
    <h3 style="line-height:33px;font-weight:bold;margin-top: 14px;margin-right: 15px;">
      <img class="wallet-icon" src="/img/wallet_icon.png" style="float:left;margin-right: 11px;">
      {{ total }} USD
    </h3>
  </div>
</template>

<style scoped>
  .wallet-icon {
    max-width:25px;
    float:right;
    margin-top:2px
  }
  .payment-buttons-effect {
    filter: gray; /* IE5+ */
    -webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
    -webkit-transition: all .8s ease-in-out;  
    transition: all .8s ease-in-out;  
  }
  .payment-buttons-effect:hover {
    filter: none;
    -webkit-filter: grayscale(0);
    cursor:pointer;
  }
</style>

<script>
  export default {
    data() { 
      return {}
    },
    props: {
      user: null
    },
    computed: {
      total() {
        let num = 0;
        if(this.$session.get('user').wallet != null) {
          num = this.$session.get('user').wallet.current_balance_usd;
        } 
        return Math.round(num * 100) / 100;
      }
    }
  }
</script>
