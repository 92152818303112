import Vue from 'vue'
import moment from 'moment'

Vue.filter('formatDatetime', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY hh:mm')
    }
});

Vue.filter('formatDateOnly', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
    }
});

Vue.filter('cardDateFormat', function(value) {
    if (value) {
        return moment(String(value)).format('HH:mm, DD MMM YYYY')
    }
});

Vue.filter('formatHourOnly', function(value) {
    if (value) {
        return moment(String(value)).format('HH:mm')
    }
});

Vue.filter('formatHourAndAOnly', function(value) {
    if (value) {
        return moment(String(value)).format('hh:mm A')
    }
});

Vue.filter('timeFromX', function(value) {
    if (value) {
        moment.locale('es');
        var a = moment(String(value));
        var b = moment(new Date());
        return a.from(b)
    }
});

Vue.filter('currencyDisplay', function(value) {
    // check if value is numeric
    if (isNaN(value) || value === null || value === undefined) {
        return '';
    }
    return value.toString().replace(',', '').replace('.', ',');
    
});
  