import Vue from 'vue'
import Router from 'vue-router'

// Main Search Flow:
import Home from '@/views/Home'
const Search = () => import('@/views/Search')// <- /{categ}/{city}/{mode?}
const SearchDetailsForm = () => import('@/views/SearchDetailsForm')// <- /search-deatils

// Auth:
const PageLogin = () => import('@/views/auth/PageLogin');
// Separate Login/Register/Password flows 
//const Login = () => import('@/views/auth/Login');
//const SignUp = () => import('@/views/auth/SignUp');
//const ForgotPassword = () => import('@/views/auth/ForgotPassword')

// Cliente:
const RequestsBySearch = () => import('@/views/clients/RequestsBySearch')// <- /get-requested-search/:id_search/:from? 
const ClientRequestChat = () => import('@/views/clients/RequestChat')// <- /get-request-chat/:search_token/:request_token? 
const MyProfile = () => import('@/views/clients/MyProfile')
//import MySearches from '@/views/clients/MySearches' // Precaucion, import() causa error con el infinite scroll
const MySearches  = () => import('@/views/clients/MySearches') // Precaucion, import() causa error con el infinite scroll

// Trabajador:
const TechSignUp = () => import('@/views/auth/TechSignUp')
const MyRequests = () => import('@/views/workers/MyRequests') 
const MyTechProfile = () => import('@/views/workers/MyTechProfile')// <- /my-tech-profile
const WorkerRequestChat = () => import('@/views/workers/RequestChat')// <- /get-request-chat/:search_token/:request_token? 
//import MyWallet from '@/views/workers/Wallet'
const MyWallet  = () => import('@/views/workers/Wallet')

// Pagos:
const ServicePaymentFlow = () => import('@/views/clients/ServicePay')

// Paginas/Errores:
const FAQ = () => import('@/views/pages/FAQ')
const PrivacyPolicy = () => import('@/views/pages/PrivacyPolicy')
const TermsConditions = () => import('@/views/pages/TermsConditions')
const NotFound = () => import('@/views/pages/NotFound')
const NoPermission = () => import('@/views/pages/NoPermission')
const ErrorPage = () => import('@/views/pages/ErrorPage')
const FreeRevPromoForm = () => import('@/views/pages/FreeRevPromoForm')

const TestBeams = () => import('@/views/TestBeams')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  //base: process.env.VUE_APP_BASEURL,
  routes: [
    {
      path: '/test-beams',
      component: TestBeams,
      meta: {
        header: 1
      } 
    },
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        header: 1,
        title: 'Tecniko - mecánicos, plomeros, electricistas, téncicos en PC y celular y linea blanca.',
        /*metaTags: [
          {name: 'description', content: 'The home page of our example app.'},
          {property: 'og:description',  content: 'The home page of our example app.'}
        ]*/
      },
      beforeEnter: (to, from, next) => {
        if(!Vue.prototype.$session.exists() || Vue.prototype.$session.exists() && Vue.prototype.$session.get('user').id_role !== 2)
          next()
        else
          next({ name: 'my-requests' });
      }
    },
    {
      path: '/tech-sign-up',
      name: 'Crear cuenta como técnico',
      component: TechSignUp,
      meta: {
        title: 'Crear cuenta como técnico.',
        header: 1
      },
      beforeEnter: (to, from, next) => {
        if(!Vue.prototype.$session.exists() ||
            Vue.prototype.$session.exists() && Vue.prototype.$session.get('user').id_role == 2)
          next()
        else
          next({ name: 'no-permission' });
      }
    },
    {
      path: '/my-profile',
      name: 'Mi Perfil',
      component: MyProfile,
      meta: {
        header: 1
      },
      beforeEnter: (to, from, next) => {
        if(Vue.prototype.$session.exists() && Vue.prototype.$session.get('user').id_role !== 2)
          next()
        else
          next({ name: 'no-permission' });
      }
    },
    {
      path: '/my-tech-profile',
      name: 'Mi Perfil Técnico',
      component: MyTechProfile,
      meta: {
        header: 1
      },
      beforeEnter: (to, from, next) => {
        if(Vue.prototype.$session.exists() && Vue.prototype.$session.get('user').id_role == 2)
          next()
        else
          next({ name: 'no-permission' });
      }
    },
    {
      //ath: '/:mode(presupuesto|urgencia)/:category/:city',
      path: '/:category(plomero|mecanico|nevera-aire-acondicionado|pc|celular|electricista|linea-blanca|fumigacion)/:city/:mode?',
      component: Search,
      meta: {
        //title: 'Buscar',
        header: 2
      }
    },
    {
      path: '/search-details',
      name: 'search_details',
      component: SearchDetailsForm,
      meta: {
        title: 'Crear búsqueda',
        header: 2
      }
    },
    {
      path: '/my-searches',
      name: 'Búsquedas de un cliente',
      component: MySearches,
      meta: {
        header: 1
      },
      beforeEnter: (to, from, next) => {
        if(Vue.prototype.$session.exists() && Vue.prototype.$session.get('user').id_role !== 2)
          next()
        else
          next({ name: 'no-permission' });
      }
    },
    {
      path: '/my-requests',
      name: 'my-requests',
      component: MyRequests,
      meta: {
        header: 1
      },
      beforeEnter: (to, from, next) => {
        if(Vue.prototype.$session.exists() && Vue.prototype.$session.get('user').id_role == 2)
          next()
        else
          next({ name: 'no-permission' });
      }
    },
    {
      path: '/get-requested-search/:id_search/:from?',// from agregado para distinguir el caso en el traking de ga
      name: 'get-requested-search',
      component: RequestsBySearch,
      meta: {
        header: 1
      }
    },
    {
      // Chat cliente-tecnico, vista del cliente
      path: '/get-client-chat/:search_token/:request_token?',// No se agregó from para ga
      name: 'get-client-chat',
      component: ClientRequestChat,
      meta: {
        header: 1
      }
    },
    {
      path: '/get-worker-chat/:request_token?',
      name: 'get-worker-chat',
      component: WorkerRequestChat,
      meta: {
        header: 1
      },
      /*beforeEnter: (to, from, next) => {
        if(Vue.prototype.$session.exists() && Vue.prototype.$session.get('user').id_role == 2)
          next()
        else
          next({ name: 'no-permission' });
      }*/
    },
    {
      path: '/not-found',
      name: 'not-found',
      component: NotFound,
      meta: {
        header: 1
      }
    },
    {
      path: '/no-permission',
      name: 'no-permission',
      component: NoPermission,
      meta: {
        header: 1
      }
    },
    {
      path: '/whoops',
      name: 'error',
      component: ErrorPage,
      meta: {
        header: 1
      }
    },
    {
      path: '/login',
      name: 'login',
      component: PageLogin,
      meta: {
        header: 0
      }
    },
    {
      path: '/confirm/:email/:token',
      name: 'confirm',
      component: PageLogin,
      meta: {
        header: 0
      }
    },
    {
      path: '/faq',
      component: FAQ,
      meta: {
        header: 1
      }
    },
    {
      path: '/politica-privacidad',
      component: PrivacyPolicy,
      meta: {
        header: 1
      }
    },
    {
      path: '/terminos-condiciones',
      component: TermsConditions,
      meta: {
        header: 1
      }
    },
    {
      path: '/promo-revision-gratis',
      component: FreeRevPromoForm,
      meta: {
        header: 1
      }
    },
    { 
      /* When a client pay a service quote */
      path: '/pay/service/:search_token/:request_token/:payment_intent_token',
      name: 'ServicePaymentFlow',
      component: ServicePaymentFlow,
      meta: {
        header: 0,
        title: 'Centro de pagos'
      }
    },
    { 
      path: '/my-wallet', 
      name: 'MyWallet',
      component: MyWallet,
      meta: {
        header: 1,
        title: 'MyWallet'
      }
    },

    /*{ 
      path: '/:catchAll(.*)', 
      component: NotFound,
      name: 'NotFound'
    },*/
  ]
});

// Set title y meta especial para busquedas, ej Plomero en Valencia. (hacerlo aca o en el componente)
/* function setTitleAndMetaTags() {
  if(to.name === 'Buscar') {
    setTitleMetaSearchPage();
  } else { }
}*/

// Runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  
  // Para history mode con soporte para hash mode
  // Redirect if fullPath begins with a hash (ignore hashes later in path)
  if (to.fullPath.substr(0,2) === "/#") {
      const path = to.fullPath.substr(2);
      next(path);
      return;
  }
  
  //setTitleAndMetaTags();
  // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    // If a route with a title was found, set the document (page) title to that value.
    if(nearestWithTitle) {
      document.title = nearestWithTitle.meta.title;
    } else if(previousNearestWithMeta) {
      document.title = previousNearestWithMeta.meta.title;
    }
    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
    // Skip rendering meta tags if there are none.
    if(!nearestWithMeta) return next();
    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta');
      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });
      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');
      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));
  next();
});

export default router;